.xcn-modal-parent {
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
    z-index: 2000; /*higher than the topbar */
    background-color:#c1b8f182;
    top: 0;
    left:0;
}

.xcn-modal-main{
    background-color: #fff;
    min-height:100vh;
    border-radius: 30px 0px 0px 30px;
    box-shadow: #bfb9e6 0px 2px 8px 0px;
    width: 25vw;
    min-width: 300px;
    z-index: 200;
}
.xcn-modal-top{
    padding: 1.5rem 1rem 0rem 1rem;
}

.xcn-modal-footer{
    margin-top: auto;
    padding:1rem;
    bottom:0;
    position: absolute;
    margin-bottom: 1rem;
    box-shadow: #f4f4f4 0px -10px 16px 0px;
    width: 25vw;
    min-width: 300px;
}
