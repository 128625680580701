/* PrivateIndex.css */

.private-layout {
    display: flex;
    min-height: 100vh;
}

.sidebar {
    width: 250px;
    background-color: #343a40;
    color: white;
    flex-shrink: 0;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.main-content {
    margin-left: 250px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.topbar {
    background-color: #f8f9fa;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
}

.content {
    background-color: #f1f6fb;
    padding: 2rem;
    flex-grow: 1;
}

.nav-link.disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

.nav-link {
    cursor: pointer !important;
}