/* Sidebar.css */

.sidebar-bg {
    background: linear-gradient(135deg, #0d1b35, #1a2a54), #0d1b35; 
}



.custom-accordion .accordion-header {
    background-color: transparent !important; 
    border: none;
}

 .custom-accordion .accordion-button {
    background-color: transparent !important; 
    color: #fff; 
    border: none; 
    box-shadow: none; 
} 

.custom-accordion .accordion-button:not(.collapsed) {
    background-color: transparent !important; 
    color: #fff; 
    border: none;
}

.custom-accordion .accordion-button:focus {
    box-shadow: none; 
}



.custom-accordion .accordion-item {
    background-color: transparent;
    border: none;
}

.custom-accordion .accordion-body {
    background-color: transparent;
    color: #fff;
    padding: 0;
}

.custom-accordion .accordion-header {
    background-color: transparent;
    border: none;
}

.custom-accordion .nav-link {
    color: #fff; 
}

.custom-accordion .nav-link.active {
    width: 100%;
    background-color: #1a2a54; 
    color: #fff;
    border-radius: 8px;
}

 .nav-link.inactive {
    color: #fff; 
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
    border: none;
}

.bnknav-cursor-disabled {
    cursor: "not-allowed";
}

