body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.content-sec{
  max-height: 100vh;
  overflow: auto;
}
.table, .css-qc6sy-singleValue, .form-control, .css-14el2xx-placeholder{
  font-size: .85rem !important;
}
.login-parent{
  background: linear-gradient(152deg, rgb(241 241 241) 0%, rgb(194 190 211) 50%, rgb(135 194 255) 100%) !important;
}
.cursor-pointer {
  cursor: pointer;
}